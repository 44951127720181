<template>
  <div class="card" @click="jump">
    <div class="top">
      <div
        class="icon"
        :style="{
          backgroundColor: taskType[dataSource.taskType].color,
        }"
      >
        <BuildOutlined />
      </div>
      <div class="progress">
        <h5>{{ taskType[dataSource.taskType].name }}</h5>
        <div class="progress-bar" v-if="![10, 2].includes(dataSource.taskType)">
          <a-progress
            :percent="dataSource.progress"
            size="small"
            :showInfo="false"
            :strokeWidth="4"
          />
        </div>
      </div>
      <div class="percent" v-if="![10, 2].includes(dataSource.taskType)">
        {{ dataSource.progress }}%
      </div>
      <div class="percent str" v-else>
        <span v-if="now < dataSource.startTime" style="color: red">
          {{ $t("Pub_Tab_NotStart") }}
          <!-- 未开始 -->
        </span>
        <span v-else>
          {{ $t("XB_UnderWay") }}
          <!-- 进行中 -->
        </span>
      </div>
    </div>
    <div class="bottom">
      <span class="project">{{ dataSource.taskName }}</span>
      <span v-if="dataSource.taskType === 6 && dataSource.lastTime === 0">
        <ClockCircleOutlined style="padding-right: 4px" />
        {{
          $t("study.learn_in", [
            dataSource.effective,
            mapDateType[dataSource.dateType],
          ])
        }}
        <!-- {{ dataSource.effective }}{{ mapDateType[dataSource.dateType] }}内学完 -->
      </span>
      <span v-else>
        <ClockCircleOutlined style="padding-right: 4px" />
        <template v-if="dataSource.endTime < now">
          {{
            dataSource.isOverdue === 1
              ? $t("study.overdue_learn")
              : $t("study.days_remaining", [0])
          }}
          <!-- "逾期可学" : "剩余0天" -->
        </template>
        <template v-else>{{ timeRmaining(dataSource.endTime) }}</template>
      </span>
    </div>
    <homework
      ref="homeworkRef"
      @updata:homework="homeworkChange"
      v-if="dataSource.taskType == 5"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { resourceType } from "@/utils/business";
import { timeRmaining } from "@/utils/tools";
import homework from "@/components/homework/index.vue";
export default {
  name: "TaskCard",
  components: {
    homework,
  },
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const router = useRouter();
    let now = Date.parse(new Date()) / 1000;

    const homeworkRef = ref(null);
    const taskType = {
      1: { name: $t("MN_TrainingProject_MHome"), color: "#82C5F0" }, // 项目培训
      11: { name: $t("MN_TrainingProject_MHome"), color: "#82C5F0" }, // 项目培训
      14: { name: $t("MN_TrainingProject_MHome"), color: "#82C5F0" }, // 项目培训
      6: { name: $t("Pub_LP"), color: "#FF9582" }, // 学习地图
      9: { name: $t("cm_newstaff"), color: "#9892FF" }, // 新人培训
      5: { name: $t("cm_subject"), color: "#5f88af" }, // 课程专题
      10: { name: $t("Sys_Exam"), color: "#81c57b" }, // 考试
      2: { name: $t("daily_exam"), color: "#44ffae" }, // 每日一练
      12: { name: $t("cm_activity"), color: "#ffdde1" }, // 活动
      16: { name: $t("Pub_Tab_CT"), color: "#ffdde1" }, // 面授
      20: { name: $t("CM_Live"), color: "#2c76f5" }, // 直播
    };
    const mapDateType = {
      1: $t("Pub_Day"), // "天"
      2: $t("mine.month"), // "月"
      3: $t("CM_Year"), // "年"
    };
    const jump = () => {
      let d = props.dataSource;
      switch (d.taskType) {
        case 1: // 培训项目 -- 项目管理
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 11: // 培训项目 -- 任务督导
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 14: // 培训项目 -- 培训实施
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 6: // 学习地图
          router.push({
            path: "/map/detail",
            query: {
              id: d.taskId,
              temp: d.mapTemplate,
            },
          });
          break;
        case 9: // 新人培训
          router.push({ path: "/newstaff/detail", query: { id: d.taskId } });
          break;
        case 5: // 课程专题
          router.push({ path: "/subject/detail", query: { id: d.taskId } });
          break;
        case 10: // 考试
          if (d.reexamId) {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
                reexamId: d.reexamId,
                taskType: 23,
              },
            });
          } else {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
              },
            });
          }
          break;
        case 2: // 每日一练
          router.push({
            path: "/exam/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 12: // 活动
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "activity",
              id: d.taskId,
              did: d.detailId,
              resourceId: d.resourceId,
              progress: d.progress,
              name: d.taskName,
              complete: d.complete,
            },
          });
          break;
        case 16: // 面授
          router.push({
            path: "/train/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 20: // 直播
          router.push({
            path: "/live/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
      }
    };

    return {
      now,
      resourceType,
      timeRmaining,
      jump,
      homeworkRef,
      taskType,
      mapDateType,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 282px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  background-color: #fff;
  padding: 15px 18px;
  float: left;
  cursor: pointer;
  .top {
    .mixinFlex(space-between);
    .icon {
      width: 32px;
      height: 32px;
      .mixinFlex(center; center);
      font-size: 16px;
      color: #fff;
      border-radius: 2px;
    }
    .progress {
      width: 156px;
      margin-right: 10px;
      h5 {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1.3;
      }
      &-bar {
        height: 19px;
      }
      &.str {
        width: 140px;
        margin-right: 0;
      }
    }
    .percent {
      font-size: 16px;
      font-family: DINAlternate-Bold, DINAlternate;
      &.str {
        line-height: 1;
        color: @color-theme;
        font-size: 14px;
      }
    }
  }
  .bottom {
    padding-top: 8px;
    font-size: 12px;
    color: #a7a7a7;
    .mixinFlex(space-between; center);
    .project {
      width: 160px;
      .mixinEllipsis(20px);
      color: #202020;
      font-size: 14px;
    }
  }
}
</style>
